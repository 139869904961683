import React from "react";
import Helmet from 'react-helmet';
import favicon from '../../../images/favicon.ico';

import pdf_file from "../../../images/pdf/vilkor/användarvillkor-crediflow-v1-221214.pdf";

function Anvandarvillkor(){
    return(
        <>
            <Helmet>
                <title>Integritetsolicy | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="användarvillkor" />
                <meta name="keywords" content="Crediflow" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="användarvillkor" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://www.crediflow.se/pdf/anvandarvillkor" />
                <link rel="cannonical" href="https://www.crediflow.se/pdf/anvandarvillkor" />
            </Helmet>

            <iframe 
                title="Användarvillkor 20221214"
                src={pdf_file}
                style={{height: '100vh',
                        width: '100vw',
            }}>
            <p>This browser does not support PDFs. Please download the PDF to view it: Download PDF </p>
            </iframe>

        </>
    )
}
export default Anvandarvillkor;